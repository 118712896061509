import { observable, action, computed, runInAction } from 'mobx'
import { configWeiXinShare, initWeiXinShareConfig } from 'utils/weixin'
import app from './request'

class UserStore {
  @observable myInformation = {}

  @observable teacherInformation = {}

  @observable libraries = []

  @observable docs = []

  /* 计算每个课程中的文档数量 */
  @computed
  get documentNumberForEachLibrary() {
    const obj = {}
    if (this.libraries.length) {
      this.libraries.forEach((library) => {
        const docsOfLibrary = []
        /* 默认课程的章节需要单独取 */
        const defaultChapter = { name: '默认章节', id: 'default', docs: [] }
        defaultChapter.docs = library.docs

        /* 获得课程所有的章节 */
        const chapterList = library.chapters.concat(defaultChapter)
        chapterList.forEach((chapter) => {
          /* 取出每个章节里的文档的文档 ID */
          const docIdOfChapter = chapter.docs

          /* 储存每个章节里的文档 */
          docIdOfChapter.forEach((docId) => {
            /* 取出docId 对应的文档 */
            const doc = this.docs.find((_doc) => _doc.id === docId)
            if (
              doc &&
              doc.status === 'open' &&
              doc.transformed === 1 &&
              doc.hasAction
            ) {
              docsOfLibrary.push(doc)
            }
          })
        })

        obj[library.id] = docsOfLibrary.length
      })
    }
    return obj
  }

  @action
  initialUserStore = (accountId) => {
    /* 获取当前登录用户的个人信息，在获取用户数据，显示用户当前拥有的课堂 */
    app
      .getMyInformation()
      .then((myInformation) => {
        runInAction(() => {
          this.myInformation = myInformation
        })
      })
      .catch((error) => {
        runInAction(() => {
          this.myInformation = {
            id: -1,
            name: '',
            nickname: '游客',
            avatar: '',
            cover: '',
            college: '',
            introduction: '',
            phone: ''
          }
        })
        if (!accountId) {
          alert('您当前尚未登录，请登录重试')
          throw error
        }
      })
      /* 网址中如果附带用户信息，使用指定的用户信息，否则使用当前登陆者的个人信息 */
      .then(() => app.getUserPage(accountId || this.myInformation.id))
      .then(({ accounts, coopLibraries, coopDocs, libraries, docs }) => {
        /* 取出作者信息 */
        const teacherInformation = accounts.find(
          (user) =>
            String(user.id) === String(accountId || this.myInformation.id)
        )

        const _libraries = libraries.concat(coopLibraries)

        const _docs = docs.concat(coopDocs)

        runInAction(() => {
          this.teacherInformation = teacherInformation
          this.libraries = _libraries
          this.docs = _docs
        })

        /* 只有处于生产环境才执行微信 JS-SDK 的初始化操作 */
        if (process.env.NODE_ENV === 'production') {
          const coverURL = this.teacherInformation.avatar
          const authorName =
            this.teacherInformation.name || this.teacherInformation.nickname
          const authorIntroduction = this.teacherInformation.introduction

          /* 初始化微信 JDK 配置 */
          initWeiXinShareConfig(window.encodeURIComponent(window.location.href))

          /* 等待微信 JS-SDK 可以使用后自定义分享相关操作 */
          window.jWeixin.ready(() => {
            configWeiXinShare({
              title: `${authorName}的个人主页`,
              desc: `${authorIntroduction} || '个人主页'`,
              link: window.location.href,
              imgUrl: coverURL
            })
          })
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          throw error
        }
      })
  }
}

export default new UserStore()

/* eslint-disable radix */
import copy from 'copy-to-clipboard'
import { wxOAuth2RedirectUrl } from 'utils/constants'

/* 检测当前页面所处的页面环境 */
const Browser = require('utils/browser')

window.$browser = new Browser(window.navigator.userAgent)

/* 获取 URL 中的某一特定的查询字符串的值 */
const getQueryString = (search, name) => {
  const reg = new RegExp(`(\\?|&)${name}=([^&]+)(&|$)`) // eslint-disable-line
  /* match 方法，如果正则表达式中不存在全局标志，那么如果没有匹配到字符串就返回 null，如果 */
  /* 匹配到字符串，则返回数组，数组中的第一个值是匹配的字符串，从第二个值开始，每个值是匹配正 */
  /* 则表达式中的每个分组的字符串，另外返回的数组还存在两个属性，一个是 index，表示匹配字符 */
  /* 串在原字符串中的起始位置，另一个是 input，表示对原字符串的引用 */
  /* 如果正则表达式中存在全局标志，那么如果没有匹配到字符串就返回 null，如果匹配到字符串就返 */
  /* 回数组，数组中的每一个值都是匹配到的字符串，但是不存在分组信息 */
  const result = search.match(reg)
  if (result !== null) {
    return result[2]
  }
  return null
}

/* 格式化时间 */
const formatTime = (time) => {
  const parseTime = new Date(time)
  /* 针对只有个位的数字，返回一个前置补零的字符串 */
  const paddingNumberWithZero = (number) =>
    number > 9 ? String(number) : `0${number}`
  return `${parseTime.getFullYear()}-${parseTime.getMonth() +
    1}-${parseTime.getDate()} ${paddingNumberWithZero(
    parseTime.getHours()
  )}:${paddingNumberWithZero(parseTime.getMinutes())}:${paddingNumberWithZero(
    parseTime.getSeconds()
  )}`
}

/* 格式化字幕时间 */
const formatDuring = (mss) => {
  let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60))
  let seconds = parseInt((mss % (1000 * 60)) / 1000)
  if (seconds < 10) {
    seconds = `0${seconds}`
  }

  if (minutes < 10) {
    minutes = `0${minutes}`
  }
  return `${minutes}:${seconds}`
}

/* 计算当前文档的评分 */
const computeScore = ({ star1, star2, star3, star4, star5 }) => {
  const score =
    (star1 + star2 * 2 + star3 * 3 + star4 * 4 + star5 * 5) /
    (star1 + star2 + star3 + star4 + star5)
  return Number.isNaN(score) ? -1 : score
}

/* 用户登录函数 */
function userLogin() {
  /* 当用户处于播放页面时，跳转之前需要把现在的地址记录下来，等到用户登录以后再跳转回原来的播放页面 */
  const originURL = window.encodeURIComponent(
    `${window.location.pathname}${window.location.hash}`
  )

  /* 微信授权跳转地址 */
  const redirectURL = wxOAuth2RedirectUrl.replace(/\{\{.*?\}\}/, originURL)

  if (window.$browser.browser === 'Wechat') {
    /* 如果当前浏览器是微信浏览器，那么就直接跳转到微信授权地址 */
    window.location.assign(redirectURL)
  } else if (
    copy(redirectURL, {
      message: '您当前尚未登录，请复制下面的链接地址到微信中打开并登录'
    })
  ) {
    alert('您当前尚未登录，请在微信中打开并登录，页面地址已经复制到剪贴板')
  }
}

export { getQueryString, formatTime, formatDuring, userLogin, computeScore }

/* eslint-disable eqeqeq */
import { observable, action, computed, runInAction } from 'mobx'
import http from 'utils/fetch'
import { configWeiXinShare, initWeiXinShareConfig } from 'utils/weixin'
import app from './request'

class ClassStore {
  @observable libraryInfo = {}

  @observable teacherInformation = {}

  @observable chapterList = []

  @action
  initialClassStore = (libraryId) => {
    app
      .getClassPage(libraryId)
      .then(({ accounts, docs, libraries }) => {
        /* 该课程信息 */
        const libraryInfo = libraries.find(
          (library) => library.id === libraryId
        )

        /* 找到该课程创建者信息 */
        /* 这里不使用全等是为了避免字符串与数字不匹配 */
        const teacherInformation = accounts.find(
          (user) => user.id == libraryInfo.accountId
        )

        /* 默认课程的章节需要单独取 */
        const defaultChapter = { name: '默认章节', id: 'default', docs: [] }
        defaultChapter.docs = libraryInfo.docs

        /* 获得课程所有的章节 */
        const chapterList = libraryInfo.chapters.concat(defaultChapter)

        /* 获得文档顺序数组 */
        const sortedDocs = libraryInfo.sortedDocs || []

        chapterList.forEach((chapter) => {
          /* 取出每个章节里的文档的文档 ID */
          const docIdOfChapter = chapter.docs

          /* 储存每个章节里的文档 */
          Object.assign(chapter, { courseList: [] })

          /* 先对每个章节内的文档进行排序 */
          docIdOfChapter.sort((prev, next) => {
            const prevIndex = sortedDocs.findIndex((_docId) => _docId === prev)
            const nextIndex = sortedDocs.findIndex((_docId) => _docId === next)

            return prevIndex - nextIndex
          })

          docIdOfChapter.forEach((docId) => {
            /* 取出docId 对应的文档 */
            const doc = docs.find((_doc) => _doc.id === docId)
            if (
              doc &&
              doc.status === 'open' &&
              doc.transformed === 1 &&
              doc.hasAction
            ) {
              chapter.courseList.push(doc)
            }
          })
        })

        runInAction(() => {
          this.libraryInfo = libraryInfo
          this.teacherInformation = teacherInformation
          this.chapterList = chapterList
        })

        /* 只有处于生产环境才执行微信 JS-SDK 的初始化操作 */
        if (process.env.NODE_ENV === 'production') {
          const title = this.libraryInfo.name
          const coverURL = this.libraryInfo.cover
          const authorName =
            this.teacherInformation.name || this.teacherInformation.nickName
          const authorIntroduction =
            this.teacherInformation.authorIntroduction || '个人简介'

          /* 初始化微信 JS-SDK 配置 */
          initWeiXinShareConfig(window.encodeURIComponent(window.location.href))

          /* 等待微信 JS-SDK 可以使用后自定义分享相关的操作 */
          window.jWeixin.ready(() => {
            configWeiXinShare({
              title: `${authorName}: 《${title}》专辑`,
              desc: authorIntroduction || title,
              link: window.location.href,
              imgUrl: coverURL
            })
          })
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          throw error
        } else {
          alert('获取数据错误，请检查访问地址')
        }
      })
  }
}

export default new ClassStore()

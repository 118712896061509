import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'mobx-react'

/* 引入 mobx store */
import store from './stores'

/* 引入全局样式 */
import './styles'

/* 引入路由 */
import Router from './router'

ReactDOM.render(
  <Provider {...store}>
    <BrowserRouter basename='/'>
      <Router />
    </BrowserRouter>
  </Provider>,
  document.getElementById('app')
)

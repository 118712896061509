/* eslint-disable array-callback-return */
/* eslint-disable function-paren-newline */
/* eslint-disable no-param-reassign */
import { observable, action, computed, runInAction, reaction, toJS } from 'mobx'
import { Toast } from 'antd-mobile'
import { computeScore, userLogin } from 'utils/utils'
import { configWeiXinShare, initWeiXinShareConfig } from 'utils/weixin'
import http from 'utils/fetch'
import app from './request'

class PlayerStore {
  /* 当前登陆者的信息 */
  @observable myInformation = {}

  /* 文档有关用户信息 */
  @observable accounts = []

  /* 当前文档的评论 */
  @observable comments = []

  /* 当前文档的信息 */
  @observable doc = {}

  /* 用户类型 */
  @observable userType = 'anonymity'

  /* 是否已经获得信息 */
  @observable hasGetInformation = false

  /* 是否显示评分页面 */
  @observable isShowScoreDialog = false

  /* 是否可以评分 */
  @observable canClick = true

  /* 评分总数 */
  @observable rateNum = 5

  /* 评分值 */
  @observable rateValue = 0

  /* 显示的图片的 URL */
  @observable showImgUrl = ''

  /* 是否显示图片 */
  @observable isShowImgVisible = false

  /* 是否显示评论框 */
  @observable isShowCommentToolBar = true

  /* 当前评论值 */
  @observable currentCommentValue = ''

  /* 是否显示图片选择对话框 */
  @observable isShowImagePickerDialog = false

  /* 要上传的图片文件 */
  @observable uploadImages = []

  /* 是否显示指定评论与回复 */
  @observable isShowReplyModal = false

  /* 当前指定的评论位置 */
  @observable selectedCommentIndex = 0

  /* 当前回复对应的评论的作者 ID */
  @observable sourceIdOfSelectedReply = -1

  /* 评论框内 placeholder 内容 */
  @observable placeHolder = '评论...'

  /* 标识回复类型(对评论回复或是对回复回复)，默认 -1 表示不是回复 */
  @observable isReplyComment = -1

  /* 当前文档播放器所需信息 */
  @observable playerSrc = {
    /* 当前文档的操作信息 */
    actionUrl: '',

    /* 当前文档音频信息 */
    audioUrl: '',

    /* 当前文档的图片信息 */
    imageUrl: [],

    /* 当前文档的状态信息 */
    status: '',

    /* 播放器宽度 */
    width: 0,

    /* 文档大小 */
    size: 0
  }

  /* 当前文档的字幕信息 */
  @observable subtitles = []

  /* 当前播放的字幕索引 */
  @observable activeSubtitleIndex = -1

  /* 用于在 iPad 上设置的播放器宽度 */
  @observable ipadPlayerWidth = 0

  /* 定时器 ID */
  @observable timeoutId = null

  @observable updateKey = ''

  @computed
  get rateArray() {
    return new Array(Number(this.rateNum)).fill('')
  }

  /* 文档名称 */
  @computed
  get title() {
    return this.doc.name || ''
  }

  /* 文档 ID */
  @computed
  get id() {
    return this.doc.id || ''
  }

  /* 文档作者 ID */
  @computed
  get accountId() {
    return this.doc.accountId || -1
  }

  /* 当前字幕开关信息 */
  @computed
  get showSubtitle() {
    return this.doc.showSubtitle || null
  }

  /* 文档图片资源 */
  @computed
  get pictures() {
    return this.doc.playPics || ''
  }

  /* 当前用户对文档评分 */
  @computed
  get star() {
    return this.doc.star || 0
  }

  /* 文档得分 */
  @computed
  get score() {
    return this.doc.ratingStatis ? computeScore(this.doc.ratingStatis) : -1
  }

  /* 当前所有用户的个人信息, 并且以信息冗余的形式将当前登录者的个人信息也保存 */
  @computed
  get usersInformation() {
    return this.accounts.concat(this.myInformation) || []
  }

  /* 当前文档的作者信息 */
  @computed
  get teacherInformation() {
    return (
      this.accounts.concat(this.myInformation).find((user) => user.id === this.doc.accountId) || {
        id: -1,
        name: '',
        nickname: '游客',
        avatar: '',
        cover: '',
        college: '',
        introduction: '',
        phone: ''
      }
    )
  }

  /* 标识评论是否被当前用户点赞的数组 */
  @computed
  get isLikedList() {
    return this.comments.length !== 0
      ? this.comments.map((comment) => {
          if (comment.approve.indexOf(this.myInformation.id) !== -1) {
            return true
          }
          return false
        })
      : []
  }

  /* 当前选中的评论 */
  @computed
  get selectedComment() {
    return this.comments.length !== 0 ? this.comments[this.selectedCommentIndex] : {}
  }

  /* 取出当前选中评论的所有回复 */
  @computed
  get replyListOfSelectedComment() {
    return this.comments.length !== 0 ? this.comments[this.selectedCommentIndex].replies : []
  }

  /* 标识回复是否被当前用户点赞的数组 */
  @computed
  get isLikedListOfReplies() {
    return this.comments.length !== 0
      ? this.comments[this.selectedCommentIndex].replies.map((reply) => {
          if (reply.approve.indexOf(this.myInformation.id) !== -1) {
            return true
          }
          return false
        })
      : []
  }

  @action
  toggleScoreDialog = () => {
    this.isShowScoreDialog = !this.isShowScoreDialog
  }

  /* 检查用户登录状态 */
  checkUserLoginStatus = () => {
    if (this.userType === 'anonymity') {
      /* 在页面地址中的 HASH 部分增加现在要执行的动作，用户登录以后可以根据这个记录直接执行登陆之前要执行的动作 */
      window.location.hash = 'status=openscore'
      userLogin()
      return false
    }
    return true
  }

  /* 点击评分按钮 */
  @action
  handleOnClickScore = () => {
    /* 检查用户是否登录 */
    if (!this.checkUserLoginStatus()) {
      return
    }
    this.toggleScoreDialog()
  }

  /* 点击删除评论按钮 */
  @action
  handleOnDeleteComment = (commentId) => () => {
    app
      .deleteCommentApi(commentId)
      .then(() => {
        const deletedComment = this.comments.find((comment) => comment.id === commentId)
        runInAction(() => {
          if (this.comments.remove(deletedComment)) {
            Toast.success('删除成功', 1)
          } else {
            Toast.fail('删除失败', 1)
          }
        })
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          throw error
        } else {
          Toast.fail('删除评论失败，请稍后重试', 1)
        }
      })
  }

  /* 点击删除回复按钮 */
  @action
  handleOnDeleteReply = (replyId, replyIndex) => () => {
    app
      .deleteReplyApi(this.selectedComment.id, replyId)
      .then(() => {
        runInAction(() => {
          this.selectedComment.replies.splice(replyIndex, 1)
        })
        Toast.success('删除成功', 1)
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          throw error
        } else {
          Toast.fail('删除评论失败，请稍后重试', 1)
        }
      })
  }

  /* 评分操作函数 */
  @action
  handleOnClickStar = (star) => {
    this.rateValue = star
    this.toggleScoreDialog()
    app
      .addScoreApi(this.id, { star: this.rateValue })
      .then((response) => {
        if (response === 'done') {
          return
        }
        runInAction(() => {
          this.doc.ratingStatis = response.doc.ratingStatis
          this.doc.star = response.doc.star
        })
        Toast.success('评分成功', 1)
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          throw error
        } else {
          alert('获取数据错误，请检查访问地址')
        }
      })
    /* 评分结束后重置 rateValue */
    this.rateValue = 0
  }

  /* 显示图片函数 */
  @action
  handleOnShowImg = (imgUrl) => () => {
    this.showImgUrl = imgUrl
    this.isShowImgVisible = !this.isShowImgVisible
  }

  /* 关闭显示图片函数 */
  @action
  handleOnCloseImage = () => {
    this.isShowImgVisible = !this.isShowImgVisible
  }

  /* 点赞评论函数 */
  @action
  handleOnApproveComment = (commentId) => () => {
    if (!this.checkUserLoginStatus()) {
      return
    }

    /* 取出点赞的评论信息 */
    const comment = this.comments.find((_comment) => _comment.id === commentId)

    /* 点赞的评论对应的数组下标 */
    const commentIndex = this.comments.findIndex((_comment) => _comment.id === commentId)

    /* 当前用户 ID */
    const accountId = this.myInformation.id

    /* 检查此条评论是否被当前用户点赞过 */
    if (!this.isLikedList[commentIndex]) {
      app.approveApi(commentId, { approve: true }).then(() => {
        runInAction(() => {
          comment.approve.push(accountId)
        })
      })
    } else {
      app.approveApi(commentId, { approve: false }).then(() => {
        runInAction(() => {
          comment.approve.remove(accountId)
        })
      })
    }
  }

  /* 点赞评论的回复函数 */
  @action
  handleOnApproveReply = (replyId) => () => {
    if (!this.checkUserLoginStatus()) {
      return
    }

    /* 取出要点赞的回复信息 */
    const reply = this.replyListOfSelectedComment.find((_reply) => _reply.id === replyId)

    /* 点赞的回复对应的数组下标 */
    const replyIndex = this.replyListOfSelectedComment.findIndex((_reply) => _reply.id === replyId)

    /* 当前用户 ID */
    const accountId = this.myInformation.id

    /* 检查该回复是否已被点赞过 */
    if (!this.isLikedListOfReplies[replyIndex]) {
      app.approveApi(replyId, { approve: true }).then(() => {
        runInAction(() => {
          reply.approve.push(accountId)
        })
      })
    } else {
      app.approveApi(replyId, { approve: false }).then(() => {
        runInAction(() => {
          reply.approve.remove(accountId)
        })
      })
    }
  }

  /* 显示与隐藏评论框 */
  @action
  toggleCommentToolBar = (tab, index) => {
    this.isShowCommentToolBar = index === 0
  }

  /* 显示与隐藏图片上传对话框 */
  @action
  toggleImagePickerDialog = () => {
    this.isShowImagePickerDialog = !this.isShowImagePickerDialog
  }

  /* 上传图片改变函数 */
  @action
  handleOnChangeUploadImages = (file, type, index) => {
    this.uploadImages.replace(file)
  }

  /* 清空上传图片数组 */
  @action
  clearUploadImages = () => {
    this.uploadImages.replace([])
  }

  /* 改变评论框内文字 */
  @action
  handleOnCommentChange = (val) => {
    this.currentCommentValue = val
  }

  /* 添加新评论 */
  @action
  handleOnAddComment = () => {
    if (!this.checkUserLoginStatus()) {
      return
    }

    if (this.currentCommentValue === '' && !this.uploadImages) {
      Toast.fail('请输入评论', 1)
    } else {
      const formData = new FormData()
      formData.append('text', this.currentCommentValue)
      formData.append('docId', this.id)
      this.uploadImages.forEach((image) => {
        formData.append('files', image.file)
      })

      app
        .addCommentApi(formData)
        .then((response) => {
          /* 更新评论信息 */
          const newComment = response
          this.addCommentInfo(newComment)
          runInAction(() => {
            this.comments.unshift(newComment)
            this.sortComments()
          })
          Toast.success('评论成功', 1)
        })
        .catch((error) => {
          if (process.env.NODE_ENV === 'development') {
            throw error
          } else {
            Toast.fail('评论失败', 1)
          }
        })
      /* 重置当前评论信息与上传图片信息 */
      this.currentCommentValue = ''
      this.clearUploadImages()
    }
  }

  /* 显示和隐藏评论回复页 */
  @action
  toggleReplyModal = () => {
    this.isShowReplyModal = !this.isShowReplyModal
  }

  /* 隐藏指定评论与其回复，并清空评论框和图片 */
  @action
  handleOnCloseReply = () => {
    this.toggleReplyModal()
    this.currentCommentValue = ''
    this.uploadImages.replace([])
    this.placeHolder = '评论...'
    this.isReplyComment = -1
  }

  /* 显示指定评论与其回复 */
  @action
  handleOnShowReply = (commentIndex) => () => {
    this.toggleReplyModal()
    this.selectedCommentIndex = commentIndex
    this.handleOnClickReplyComment()
  }

  /* 点击评论详情页的评论下的回复 */
  @action
  handleOnClickReplyComment = () => {
    const originName = this.usersInformation.find((user) => user.id === this.selectedComment.accountId).name
    this.isReplyComment = true
    this.placeHolder = `回复${originName}`
  }

  /* 点击评论详情页的回复下的回复 */
  @action
  handleOnClickReplyReply = (accountId, sourceId) => () => {
    const originName = this.accounts.find((user) => user.id === accountId).name
    this.sourceIdOfSelectedReply = sourceId
    this.isReplyComment = false
    this.placeHolder = `回复${originName}`
  }

  /* 添加回复 */
  @action
  handleOnAddReply = () => {
    const replyFormData = new FormData()
    replyFormData.append('commentId', this.selectedComment.id)
    replyFormData.append('sourceId', this.isReplyComment ? this.selectedComment.accountId : this.sourceIdOfSelectedReply)

    if (this.currentCommentValue === '' && !this.uploadImages) {
      Toast.fail('请输入回复', 1)
    } else {
      this.uploadImages.forEach((image) => {
        replyFormData.append('files', image.file)
      })
      replyFormData.append('text', this.currentCommentValue)
      app
        .addReplyApi(this.selectedComment.id, replyFormData)
        .then((response) => {
          const alteredComment = response
          this.addCommentReplyInfo(alteredComment)
          runInAction(() => {
            this.comments[this.selectedCommentIndex] = alteredComment
            this.currentCommentValue = ''
            this.clearUploadImages()
          })
          Toast.success('回复成功！', 1)
        })
        .catch((error) => {
          if (process.env.NODE_ENV === 'development') {
            throw error
          } else {
            Toast.fail('回复失败!', 1)
          }
        })
    }
  }

  /* 改变字幕焦点 */
  @action
  handleOnSubtitleChange = (event) => {
    this.activeSubtitleIndex = event.detail.subtitleIndex
  }

  /* 当前浏览者用户类型 */
  @action
  setUserType = () => {
    if (this.myInformation && this.myInformation.id !== -1) {
      /* 当前登录用户是当前文档的创建者 */
      if (this.myInformation.id === this.teacherInformation.id) {
        this.userType = 'master'
        return
      }
      /* 当前登录用户不是该文档的创建者，返回当前登录用户的 ID */
      this.userType = this.myInformation.id
      return
    }
    this.userType = 'anonymity'
  }

  /* 对用户评论排序 */
  @action
  sortComments = () => {
    this.comments.replace(
      this.comments.slice().sort((prev, next) => {
        const prevTop = Date.parse(prev.top)
        const nextTop = Date.parse(next.top)

        if (prevTop === 0 && nextTop === 0) {
          /* 按照评论的时间排序 */
          return Date.parse(next.createTime) - Date.parse(prev.createTime)
        }

        /* 若仅一条评论有置顶，则该评论在前，两者都有置顶，比较置顶时间，后置顶的在前 */
        if (prevTop === 0 && nextTop !== 0) {
          return 1
        }
        if (prevTop !== 0 && nextTop === 0) {
          return -1
        }
        return nextTop - prevTop
      })
    )
  }

  /* 对单条评论下的回复排序 */
  @action
  sortReplies = (replies) => replies.sort((prev, next) => Date.parse(next.createTime) - Date.parse(prev.createTime))

  /* 补充每条评论的信息 */
  @action
  addCommentInfo = (comment) => {
    const commenterInfo = this.usersInformation.find((user) => user.id === comment.accountId)

    comment.userName = commenterInfo.name || commenterInfo.nickname
    comment.avatar = commenterInfo.avatar

    return comment
  }

  /* 补充每条回复的信息 */
  @action
  addReplyInfo = (reply) => {
    /* 回复者的个人信息 */
    const replyUserInfo = this.usersInformation.find((user) => user.id === reply.accountId)

    /* 对应评论作者的个人信息 */
    const originUserInfo = this.usersInformation.find((user) => user.id === reply.sourceId)

    reply.userName = replyUserInfo.name || replyUserInfo.nickname
    reply.avatar = replyUserInfo.avatar
    reply.originUserName = originUserInfo.name || originUserInfo.nickname
    reply.originAvatar = originUserInfo.avatar

    return reply
  }

  /* 补充每条评论和及其回复的用户信息 */
  @action
  addCommentReplyInfo = (comment) => {
    this.addCommentInfo(comment)

    /* 将每条评论的回复按照创建时间排序 */
    /* observable 数组的 sort 方法会返回一份排序后的拷贝，故使用其特有的 replace 替换 */
    /* 补充信息主要用在初始化和添加回复上，初始化时传入的 comment 是 observable 类型的数组；而添加回复时，传入是普通 array，所以做下面的判断。  */
    const sortedReplies = this.hasGetInformation ? this.sortReplies(comment.replies) : comment.replies.replace(this.sortReplies(comment.replies.slice()))

    sortedReplies.forEach((reply) => {
      this.addReplyInfo(reply)
    })
  }

  /* 页面卸载时清空 playerSrc 对象 */
  @action
  clearPlayerSrc = () => {
    window.clearTimeout(this.timeoutId)
    this.playerSrc = {
      actionUrl: '',
      audioUrl: '',
      imageUrl: [],
      status: '',
      width: 0,
      size: 0
    }
    this.hasGetInformation = false
  }

  @action
  handleOnFetchLiveStatus = (docId) => {
    app.fetchLiveStatus(docId).then(({ live }) => {
      runInAction(() => {
        this.playerSrc.status = live ? 'live' : 'normal'
      })
    })
  }

  /* status 改变了说明重新装载播放器 */
  reaction1 = reaction(
    () => this.playerSrc.status,
    () => {
      this.updateKey = Math.random()
    }
  )

  /* 这里的这种实现方式很不好，页面完全重新刷新 */
  reaction2 = reaction(
    () => this.updateKey,
    () => {
      if (this.hasGetInformation) {
        window.location.reload()
      }
    }
  )

  @action
  /* 每 5 秒获取一次页面是否直播状态 */
  fetchLiveStatus = (docId) => {
    window.clearTimeout(this.timeoutId) // 这里必须使用分号与后面的立即执行函数分隔开
    ;(function F(self) {
      self.handleOnFetchLiveStatus(docId)
      self.timeoutId = window.setTimeout(F, 5000, self)
    })(this)
  }

  /* 初始化 store */
  @action
  initialPlayerStore = (docId) => {
    app
      .getMyInformation()
      .then((myInformation) => {
        runInAction(() => {
          this.myInformation = myInformation
        })
      })
      .catch((error) => {
        runInAction(() => {
          this.myInformation = {
            id: -1,
            name: '',
            nickname: '游客',
            avatar: '',
            cover: '',
            college: '',
            introduction: '',
            phone: ''
          }
        })
      })
      .then(() => http.get(`${this.myInformation.id === -1 ? '/players' : '/docs'}/${docId}?isPhone=true`))
      .then(({ accounts, comments, doc }) => {
        runInAction(() => {
          this.accounts = accounts
          this.comments = comments
          this.doc = doc
          this.sortComments()
          this.comments.forEach((comment) => {
            this.addCommentReplyInfo(comment)
          })
          this.setUserType()
        })

        /* 判断当前浏览器环境，如果是 iPad 则指定播放器宽度 */
        if (window.$browser.device === 'Tablet') {
          switch (window.innerWidth) {
            /* 9.7 寸横屏、12.9寸竖屏 */
            case 1024:
              this.ipadPlayerWidth = 768
              break
            /* 10.5 寸横屏 */
            case 1112:
              this.ipadPlayerWidth = 834
              break
            /* 12.9 寸横屏 */
            case 1366:
              this.ipadPlayerWidth = 1024
              break
            default:
          }
        }

        const playerWidth = this.ipadPlayerWidth ? this.ipadPlayerWidth : window.innerWidth

        /* 正在浏览的文档所属的发布者流量用尽了，其所发布的所有文档都不能再被浏览和观看 */
        if (this.doc.isLimit) {
          const status = this.myInformation.id === this.accountId ? 'limit1' : 'limit2'
          runInAction(() => {
            this.playerSrc.status = status
            this.playerSrc.width = playerWidth
          })
        } else {
          /* 获取应当播放的 action */
          const playingAction = this.doc.action.find((_action) => _action.id === this.doc.defaultAction)

          const isLive = this.doc.live

          /* 获取对应的字幕数据 */
          runInAction(() => {
            this.playerSrc = {
              actionUrl: playingAction.json,
              audioUrl: playingAction.recording,
              imageUrl: this.pictures.slice(),
              status: isLive ? 'live' : 'normal',
              width: playerWidth,
              size: playingAction.totalSize
            }
            this.subtitles = playingAction.subtitle
          })

          /* 只有处于生产环境才执行微信 JS-SDK 的初始化操作，并需要在 axios 配置好了以后再执行 */
          if (process.env.NODE_ENV === 'production') {
            const coverURL = this.pictures[0]
            const authorName = this.teacherInformation.name || this.teacherInformation.nickname
            const authorInroduction = this.teacherInformation.introduction || '个人简介'

            /* 初始化 JS-SDK 配置 */
            initWeiXinShareConfig(window.encodeURIComponent(window.location.href))

            /* 等待微信 JS-SDK 可以使用后自定义分享相关的操作 */
            window.jWeixin.ready(() => {
              configWeiXinShare({
                title: `${authorName}:《${this.title}》`,
                desc: doc.introduction || authorInroduction || this.title,
                link: window.location.href,
                imgUrl: coverURL
              })
            })
          }
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          throw error
        } else {
          // eslint-disable-next-line no-console
          console.log(error)
        }
      })
      .then(() => {
        runInAction(() => {
          this.hasGetInformation = true
        })
      })
  }
}

export default new PlayerStore()

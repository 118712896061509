import http from 'utils/fetch'

const app = {
  getMyInformation: () => http.get('/accounts'),
  getUserPage: (accountId) => http.get(`/players/accounts/${accountId}`),
  getClassPage: (classId) => http.get(`/publiclibraries/${classId}`),
  getPlayerPage: (docId) => http.get(`/players/${docId}`),
  addScoreApi: (docId, data) => http.put(`/docs/${docId}/rating`, data), // data 形如 {star: 5}，
  approveApi: (id, data) => http.put(`comments/${id}/approve`, data), // data 形如 {approve: true}
  addCommentApi: (data) => http.post(`/comments/`, data),
  addReplyApi: (commentId, data) => http.post(`/comments/${commentId}/reply`, data),
  deleteCommentApi: (commentId) => http.delete(`/comments/${commentId}`),
  deleteReplyApi: (commentId, replyId) => http.delete(`/comments/${commentId}/reply/${replyId}`),
  fetchLiveStatus: (docId) => http.get(`/players/${docId}/liveStatus`)
}

export default app

/* eslint-disable class-methods-use-this */

import React, { Fragment, Suspense, lazy } from 'react'
import { Route } from 'react-router-dom'
import { hot } from 'react-hot-loader/root'
import http from 'utils/fetch'
import { userLogin } from 'utils/utils'

const Home = lazy(() =>
  import(/* webpackChunkName: "Home" */ 'containers/Home')
)

const Price = lazy(() =>
  import(/* webpackChunkName: "Price" */ 'containers/Price')
)

const User = lazy(() =>
  import(/* webpackChunkName: "User" */ 'containers/User')
)

const Class = lazy(() =>
  import(/* webpackChunkName: "Class" */ 'containers/Class')
)

const Player = lazy(() =>
  import(/* webpackChunkName: "Player" */ 'containers/Player')
)

class Routes extends React.Component {
  state = {
    error: null
  }

  componentDidMount() {
    /* 设置请求拦截器，拦截所有的 401 请求，并在需要授权的时候跳转到微信授权跳转地址 */
    http.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        const {
          config: { method, url },
          response: { status: statusCode } = {}
        } = error

        /* 用户尚未登录的情况 */
        if (statusCode === 401) {
          if (method.toUpperCase() === 'GET' && url === '/api/v1/accounts') {
            console.log(' 获取个人信息失败') // eslint-disable-line
          } else if (window.location.pathname.startsWith('/player')) {
            userLogin()
          }
        }
        return Promise.reject(error)
      }
    )
  }

  render() {
    return (
      <Suspense fallback={<div>Loading</div>}>
        <Route path='/' component={Home} exact strict />
        <Route path='/price' component={Price} exact strict />
        <Route path='/user' component={User} exact strict />
        <Route path='/class/:classId' component={Class} exact strict />
        <Route path='/player/:docId' component={Player} exact strict />
      </Suspense>
    )
  }
}

export default hot(Routes)
